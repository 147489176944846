.even-row {
    background-color: #d6ebea !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    margin-bottom: 2px;
}

.odd-row {
    background-color: white !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 2px;
}

.row-selected {
    background-color: #a82b2b !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
}

.row-partial-selected {
    background-color: #ff7e6d !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
}

.out-of-stock-row {
    background-color: #eb818a !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
}

.expired-stock-row {
    background-color: #77020c !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6);
}