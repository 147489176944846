.search-bill-div {
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-break: break-word;
    padding: 0;
    margin: 0;
  }
  
  .search-bill-div:hover {
    background-color: #e1fffb;
  }
  